import * as React from 'react'
import '../style/common.css'
import '../style/large.css'
import 'lightgallery.js/dist/css/lightgallery.css'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { getInitLanguage } from '../services/i18n.service'
import map from '../images/mapMargaritaSifnos.png'
import GoogleMapsIcon from '../assets/Google_Maps_Logo.svg'
import { SEO } from '../components/seo'
import { FaBus, FaTaxi, FaCarSide} from 'react-icons/fa'

// styles
const pageStyles = {
  color: '#484848',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const LocationPage = () => {
  const [locale, setLocale] = React.useState(getInitLanguage())
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  React.useEffect(() => {
    setLocale(getInitLanguage())
  }, [])

  return <main style={pageStyles}>
    <SEO />
    <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} locale={locale} setLocale={setLocale}></Header>
    <div className='room__details'>
      <h2 className='room__details-title'>{locale.how_to_reach}</h2>
      <a className='location__link--maps button__link' target='_blank'
        // eslint-disable-next-line max-len
        href='https://www.google.com/maps/place/Margarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece/@36.9688419,24.7217233,17z/data=!4m6!1m3!3m2!1s0x1498f2ac200f57b5:0xd8da8fac8049c726!2sMargarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece!3m1!1s0x1498f2ac200f57b5:0xd8da8fac8049c726'>
        {locale.take_me_there} <br />
        <GoogleMapsIcon className='location__icon--maps'/>
      </a>
    </div>
    <div className='location__text-wrapper'>
      <div className='location__text-text gray-bg'>
        <h2 className='txt--center txt--secondary'>{locale.further_instructions}</h2>
        <h3 className='txt--left'>{locale.from_kamares_title}</h3>
        <h4 className='txt--secondary'><FaBus/> {locale.bus_title}</h4>
        <p>{locale.bus_text}</p>
        <p>{locale.in_katavati_text}</p>
        <div className='location__text-image'>
          <img loading='lazy' width={'90%'} src={map}></img>
        </div>
        <br/>
        <h4 className='txt--secondary'><FaTaxi/> {locale.taxi_title}</h4>
        <p>{locale.taxi_text}</p>
        <br/>
        <h4 className='txt--secondary'><FaCarSide/> {locale.vehicle_title}</h4> 
        <p>{locale.vehicle_text_first}</p>
        <p>{locale.vehicle_text_second}</p>
      </div>
    </div>

    <Footer />
  </main>
}

export default LocationPage
